import React from 'react';
import { Link } from 'react-router-dom';

const LandingPageNaviBar = () => (
  <nav className="navbar navbar-expand-lg navbar-light bg-light">
    <div className="container-fluid">
      <Link className="navbar-brand" to="/">Ace JLPT</Link>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav">

          <li className="nav-item">
            <Link className="nav-link" to="/about_jlpt">About JLPT</Link>
          </li>

          <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Learning Resources
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <Link className="dropdown-item" to={`/resources/textbooks`}>Textbooks</Link>
                <Link className="dropdown-item" to={`#`}>Vocabulary List</Link>
                <Link className="dropdown-item" to={`#`}>Grammar List</Link>
                <Link className="dropdown-item" to={`#`}>Kanji List</Link>
              </div>
            </li>

          <li className="nav-item">
            <Link className="nav-link" to="/level_test">Proficiency Level Test</Link>
          </li>

          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownMenuLink"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              JLPT Question Bank
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <Link className="dropdown-item" to={`/n1`}>N1</Link>
              <Link className="dropdown-item" to={`/n2`}>N2</Link>
              <Link className="dropdown-item" to={`/n3`}>N3</Link>
              <Link className="dropdown-item" to={`/n4`}>N4</Link>
              <Link className="dropdown-item" to={`/n5`}>N5</Link>
            </div>
          </li>


        </ul>
      </div>
    </div>
  </nav>
);

export default LandingPageNaviBar;