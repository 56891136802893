import React from 'react';
import LandingPageNaviBar from './LandingPageNaviBar';

function LevelTest() {

  return (
    <div>
      <LandingPageNaviBar />

      <div className='container'>
        <br></br>
        <p>level test to be developed.</p>
        <a href="/">go main page</a>
      </div>
    </div>
  )
}

export default LevelTest;
