import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

function Subject() {
  const { level, subject } = useParams();
  const navigate = useNavigate();

  const capitalize = (s) => s && s.charAt(0).toUpperCase() + s.slice(1);

  const cardData = [
    { date: '2023.12', yearMonth: '202312' },
    { date: '2023.07', yearMonth: '202307' },
    { date: '2022.12', yearMonth: '202212' },
    { date: '2022.07', yearMonth: '202207' },
    { date: '2021.12', yearMonth: '202112' },
    { date: '2021.07', yearMonth: '202107' },
    { date: '2020.12', yearMonth: '202012' },
    { date: '2020.07', yearMonth: '202007' },
    { date: '2019.12', yearMonth: '201912' },
    { date: '2019.07', yearMonth: '201907' },
  ];

  const cards = []; // Array to hold JSX for each card

  // Use a for loop to create each card component and push it to the `cards` array
  for (let i = 0; i < cardData.length; i++) {
    const { date, yearMonth } = cardData[i];
    cards.push(
      <div 
        key={yearMonth} 
        className='card p-3 mb-3' 
        onClick={() => navigate(`/${level}/practice/${subject}/${yearMonth}`)} 
        style={{ cursor: 'pointer' }}
      >
        <h5>{date} {level.toUpperCase()} {capitalize(subject)}</h5>
        <p>Completed: 100%</p>
        <div className='progress'>
          <div className='progress-bar' role='progressbar'></div>
        </div>
      </div>
    );
  }

  return (
    <div className='container'>
      <br />
      <h3>{level.toUpperCase()} {capitalize(subject)}</h3>
      <br />

      {cards} 
    </div>
  );
}

export default Subject;
