import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import QuizForm from '../../../components/QuizForm/QuizForm';

function SubjectQuiz(){
  const { level } = useParams();
  const { subject } = useParams();
  const { yearMonth } = useParams();

  const [quizData, setQuizData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetching the JSON data
    fetch(`/docs//${level}/${yearMonth}_${level}.json`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const quizData = data.filter(item => 
          item.questionTypeLevel2 && item.questionTypeLevel2.toLowerCase() === subject.toLowerCase()
        );
        setQuizData(quizData); // Update state with fetched data
        setLoading(false); // Set loading to false after data is loaded
      })
      .catch(error => {
        console.error('Error fetching the JSON:', error);
        setError(error); // Update error state
        setLoading(false); // Set loading to false even if there was an error
      });
  }, []); // Empty dependency array to run once on component mount

  // Handle loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Handle error state
  if (error) {
    return <div>this quiz data is not ready, please use other year's</div>;
  }


  return (
    <div className='container'>
      <br></br>
      <h4>{level} {subject} {yearMonth}</h4>
      <QuizForm quizData={quizData}/>
    </div>
  );
}

export default SubjectQuiz;
