import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const { level } = useParams();
  const navigate = useNavigate();

  return (
    <div className="container">
      <br></br>
      <h3>{`Practice Your JLPT ${level.toUpperCase()} with real JLPT questions in the past 10 years`}</h3>

      <br></br>

      <div 
        key={level} 
        className='card p-3 mb-3' 
        onClick={() => navigate(`/${level}/vocabulary`)} 
        style={{ cursor: 'pointer' }}
      >
        <h5>{`JLPT ${level.toUpperCase()} Vocabulary`}</h5>
        <p>Completed: 100%</p>
        <div className='progress'>
          <div className='progress-bar' role='progressbar'></div>
        </div>
      </div>

      <div 
        key={level} 
        className='card p-3 mb-3' 
        onClick={() => navigate(`/${level}/grammar`)} 
        style={{ cursor: 'pointer' }}
      >
        <h5>{`JLPT ${level.toUpperCase()} Grammar`}</h5>
        <p>Completed: 100%</p>
        <div className='progress'>
          <div className='progress-bar' role='progressbar'></div>
        </div>
      </div>

      <div 
        key={level} 
        className='card p-3 mb-3' 
        onClick={() => navigate(`/${level}/reading`)} 
        style={{ cursor: 'pointer' }}
      >
        <h5>{`JLPT ${level.toUpperCase()} Reading`}</h5>
        <p>Completed: 100%</p>
        <div className='progress'>
          <div className='progress-bar' role='progressbar'></div>
        </div>
      </div>

      <div 
        key={level} 
        className='card p-3 mb-3' 
        onClick={() => navigate(`/${level}/listening`)} 
        style={{ cursor: 'pointer' }}
      >
        <h5>{`JLPT ${level.toUpperCase()} Listening`}</h5>
        <p>Completed: 100%</p>
        <div className='progress'>
          <div className='progress-bar' role='progressbar'></div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
