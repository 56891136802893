import React from 'react';
import { Link } from 'react-router-dom';
import LandingPageNaviBar from './LandingPageNaviBar';
import '../../styles/custom.css';
import './LandingPage.css';

const LandingPage = () => {
  const levels = ['n1', 'n2', 'n3', 'n4', 'n5'];

  return (
    <div className="landing-page">
      <LandingPageNaviBar />

      <br></br>

      <div className="container card p-3 mb-3">
        <br></br>
        <h1 className='justify-content-center text-center '>
          Your One-Stop Japanese Resource Website to Ace your JLPT. 
        </h1>
        <p>Welcome to Your One-Stop Japanese Resource Website - <strong>AceJLPT!</strong> </p>
        <p>We're here to guide you on an intensive and effective journey to mastering the Japanese language and acing the JLPT. Whether you’re aiming for N5 or striving to conquer N1, our comprehensive platform is designed to provide you with the most efficient tools and resources to reach your goal in record time.</p>
        <p>Dive into our interactive quizzes, access extensive vocabulary lists, and master key grammar points—all tailored to help you feel confident and prepared for the big day. Let’s embark on this journey together and make your dream of passing the JLPT a reality in just three months!</p>
        <br></br>
      </div>

      <div className="container mt-4">
        <div className="row align-items-center">
          <div className="col-3 col-md-2 text-center">
            <img src={require('../../assets/task-list.png')} className="img-fluid" style={{ maxHeight: '100px' }} alt="Task List" />
          </div>
          <div className="col-9 col-md-10">
            <h2>JLPT Question Bank</h2>
            <h5 className="text-secondary">Practice by topic using real JLPT exam questions</h5>
          </div>
        </div>
        <br />
        <div className='justify-content-center'>
          <div className="row">
            {levels.map((level) => (
              <div className="col-md-2" key={level}>
                <div className="card">
                  <div className="card-header justify-content-center text-center">
                    <strong>{level.toUpperCase()}</strong>
                  </div>
                  <div className="card-body">
                    <ul className="list-group">
                      <li className="list-group-item justify-content-center text-center">
                        <Link to={`/${level.toLowerCase()}/vocabulary`}>Vocabulary</Link>
                      </li>
                      <li className="list-group-item justify-content-center text-center">
                        <Link to={`/${level.toLowerCase()}/grammar`}>Grammar</Link>
                      </li>
                      <li className="list-group-item justify-content-center text-center">
                        <Link to={`/${level.toLowerCase()}/reading`}>Reading</Link>
                      </li>
                      <li className="list-group-item justify-content-center text-center">
                        <Link to={`/${level.toLowerCase()}/listening`}>Listening</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>


      </div>

      <div className="container mt-4">
        <div className="row align-items-center">
          <div className="col-3 col-md-2 text-center">
            <img src={require('../../assets/ask.png')} className="img-fluid" style={{ maxHeight: '100px' }} alt="Task List" />
          </div>
          <div className="col-9 col-md-10">
            <h2>Japanese Proficiency Test</h2>
            <h5 className="text-secondary">Not sure what is your Japanese proficiency level? Test with 10 questions</h5>
          </div>
        </div>
      </div>

      <br />

      <div className='container text-center'>
        <Link to="/level_test" className="btn btn-primary btn-lg px-4 py-3">
          Test Your Japanese Proficiency with 10 Questions!
        </Link>
      </div>
      <br />
      <br />
    </div>
  );
};

export default LandingPage;
