// App.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import LandingPageNaviBar from './LandingPageNaviBar';

function AboutJLPT() {
  const markdown = `
# Understanding of JLPT (the Japanese Language Proficiency Test)

If you plan to study Japanese, the Japanese Language Proficiency Test (JLPT) is a vital certification to consider. While there are other language assessments available, the JLPT is the most widely accepted way to evaluate your Japanese language proficiency, particularly for those pursuing academic or career opportunities in Japan.

Dive into all you need to know about the JLPT with AceJLPT.com, along with effective study techniques and practice materials to enhance your preparation and set you up for success!

## What Is the JLPT Test?

The 日本語能力試験 (nihongo nōryoku shiken), commonly referred to in English as the JLPT, is a standardized assessment aimed at evaluating and certifying proficiency in the Japanese language.

Established in 1984 by the Japan Foundation and Japan Educational Exchanges and Services (JEES), the JLPT has gained significant recognition. According to the official JLPT website, over one million students around the globe participated in the test in 2018, solidifying its status as the most comprehensive Japanese language examination worldwide.

## Where and When to Take the Test?

The JLPT is conducted twice a year, in July and December, across the globe.

To register, you must sign up at the Japanese language institution in your country that organizes and administers the JLPT. If you're unsure where to take the test, you can find a list of authorized institutions on [the official JLPT website](https://www.jlpt.jp/e/application/overseas_list.html).

The coordinating institution in your country will provide details regarding registration deadlines and exam dates. Typically, registration for the July session begins in March, while the December session opens in September. However, it's always wise to verify this information to ensure accuracy.

To register for the exam, a fee is required. So, how much does it cost to take the JLPT? The price varies depending on the country. In the United States, the fee is approximately 6,500 JPY (Japanese yen), which is about 60 US dollars.

`;

  return (
    <div>
      <LandingPageNaviBar />
      <div className="container">
        <img src={require('../../assets/jlpt-logo.webp')} className="mb-3 img-fluid" alt="JLPT Logo" />
        <Markdown remarkPlugins={[remarkGfm]}>{markdown}</Markdown>
      </div>
    </div>
  );
}

export default AboutJLPT;
