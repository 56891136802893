import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';

function QuizResults({ quizData, answers }) {
  return (
    <div>
      <h2>Quiz Completed!</h2>
      <h3>Your Answers:</h3>
      <ul>
        {quizData.map((question, index) => (
          <li key={index}>
            {question.question} - 
            <strong>
              {answers[`question${index}`] === question.options[question.correctAnswer - 1] ? " Correct" : " Incorrect"}
            </strong>
          </li>
        ))}
      </ul>
    </div>
  );
}

function QuizForm({ quizData = [] }) {
  const { level, yearMonth } = useParams();

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [isQuizCompleted, setIsQuizCompleted] = useState(false); // State to track if the quiz is completed

  const formik = useFormik({
    initialValues: quizData.reduce((acc, _, index) => {
      acc[`question${index}`] = ''; // Initialize answers for each question
      return acc;
    }, {}),
    onSubmit: (values) => {
      console.log("Quiz Results:", values); // Log all answers at the end
      setAnswers(values); // Save the final answers for display
    },
  });

  const handleOptionChange = (selectedOption, selectedIndex) => {
    const currentAnswerKey = `question${currentQuestionIndex}`;
    const correctAnswerIndex = quizData[currentQuestionIndex].correctAnswer - 1;

    // Save the answer and check if it's correct
    formik.setFieldValue(currentAnswerKey, selectedOption);
    setAnswers((prev) => ({
      ...prev,
      [currentAnswerKey]: selectedOption,
    }));
    setIsAnswerCorrect(selectedIndex === correctAnswerIndex + 1);
  };

  const handleNext = () => {
    if (currentQuestionIndex === quizData.length - 1) {
      setIsQuizCompleted(true); // Mark quiz as completed when last question is answered
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setIsAnswerCorrect(null); // Reset correctness display for the next question
    }
  };

  const handleEndQuiz = () => {
    setIsQuizCompleted(true); // Set quiz to completed when "End and See Results" is clicked
  };

  return (
    <div>
      {isQuizCompleted ? ( // Check if quiz is completed
        <QuizResults quizData={quizData.filter((_, index) => answers[`question${index}`] !== undefined)} answers={answers} /> // Only display answered questions
      ) : (
        currentQuestionIndex < quizData.length && (
          <div className='bg-light rounded border p-3'>
            {/* Display only the current question */}
            <p> Question {currentQuestionIndex + 1}/{quizData.length}</p> 
            <p>{quizData[currentQuestionIndex].questionDescription}</p>
            {quizData[currentQuestionIndex].article && (
              <div>
                <p dangerouslySetInnerHTML={{ __html: quizData[currentQuestionIndex].article }} className='fw-bold'></p>
                <br/>
              </div>
            )}
            {quizData[currentQuestionIndex].question && quizData[currentQuestionIndex].question !== null && (
              <h4
                style={{ color: "inherit" }} // Preserve other styling
                dangerouslySetInnerHTML={{
                  __html: quizData[currentQuestionIndex].question.replace(
                    /<u>/g,
                    '<u style="text-decoration-color: rgb(240,76,83);">'
                  ),
                }}
              />
            )}
            {quizData[currentQuestionIndex].audio &&  quizData[currentQuestionIndex].audio !== null && (
              <div className="container mt-3">
                <div className="card p-3">
                  <audio controls className="w-100">
                    <source src={`/docs/${level}/audio/${yearMonth}_${level}_${quizData[currentQuestionIndex].questionId}.mp3`} type="audio/mpeg" />
                    {/* <source src={quizData[currentQuestionIndex].audio} type="audio/mpeg" /> */}
                    Your browser does not support the audio element.
                  </audio>
                </div>
              </div>
            )}

            {/* Display answer options */}
            {quizData[currentQuestionIndex].options.map((option, index) => {
              const isCorrect = index === (quizData[currentQuestionIndex].correctAnswer);
              const isSelected = formik.values[`question${currentQuestionIndex}`] === option;

              return (
                <div key={index} className="rounded border p-2 m-2">
                  <label>
                    <input
                      type='radio'
                      name={`question${currentQuestionIndex}`}
                      value={option}
                      checked={isSelected}
                      onChange={() => handleOptionChange(option, index)}
                      disabled={isAnswerCorrect !== null} // Disable after selection
                    />
                    {option}
                    {isAnswerCorrect !== null && isSelected && !isCorrect && (
                      <span className="text-danger ms-2">❌</span> // Show "X" for wrong answers
                    )}
                    {isAnswerCorrect !== null && isCorrect && !isSelected && (
                      <span className="text-success ms-2">✅</span> // Show tick for correct answers
                    )}
                    {isAnswerCorrect !== null && isSelected && isCorrect && (
                      <span className="text-success ms-2">✅</span> // Show tick for correct answers only once
                    )}
                  </label>
                  <br />
                </div>
              );
            })}

            {/* "Next" button to proceed after answer is shown */}
            <div className="mt-2 d-flex justify-content-between">
            <button
                type="button"
                onClick={handleEndQuiz} // Call the new end quiz function
                className="btn flex-grow-1"
                style={{ maxWidth: "200px", border: "2px solid green" }} // Set a maximum width
              >
                End and See Results
              </button>
              <button
                type="button"
                onClick={handleNext}
                className={`btn ${isAnswerCorrect !== null ? "btn-success" : "btn-secondary"} flex-grow-1 me-2`}
                disabled={isAnswerCorrect === null} // Disable when isAnswerCorrect is null
                style={{ maxWidth: "200px" }} // Set a maximum width
              >
                {currentQuestionIndex === quizData.length - 1 ? "Finish Quiz" : "Next Question"}
              </button>

            </div>

            {/* Show if the answer is correct or incorrect */}
            {isAnswerCorrect !== null && (
              <div>
                {isAnswerCorrect ? (
                  <strong style={{ color: "green" }} className='fw-bold'>✅ Correct!</strong>
                ) : (
                  <strong style={{ color: "red" }} className='fw-bold'>❌ Incorrect</strong>
                )}
                {quizData[currentQuestionIndex].explanation && (
                  <p dangerouslySetInnerHTML={{ __html: quizData[currentQuestionIndex].explanation.replace('style=\"display:none\"', '') }} />
                )}
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
}

export default QuizForm;
